export const settingsPlanUrl = '/settings/plan'
export const signInPath = '/signin'
export const signOutPath = '/signout'
export const refreshAuthPath = '/auth'
export const adminSignInPath = '/admin/signin'


export const helpNewTicketUrl = 'https://automatecommerce.zendesk.com/hc/en-us/requests/new'
export const helpCenterUrl = 'https://automatecommerce.zendesk.com/hc/en-us/categories/900000022226'
export const helpRulesUrl = 'https://automatecommerce.zendesk.com/hc/en-us/articles/900000431386'

