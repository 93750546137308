import './style.scss'

import { withFirebase } from 'providers/firebase'

import React, { useCallback } from "react"
import { useSelector, useDispatch } from 'react-redux'

// import { TitleBar } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import createApp from '@shopify/app-bridge';

import {
  Layout,
  Page,
  Card,
  Stack,
  Button,
  TextStyle,
  DisplayText,
  PageActions,
  List,
  SkeletonDisplayText,
  SkeletonBodyText,
  Icon,
  Banner,
} from "@shopify/polaris";

import {
  SecureMajor,
  ArrowRightMinor,
} from '@shopify/polaris-icons';

import axios from 'axios'
import { DateTime } from 'luxon'

import { settingsPlanUrl } from 'utils/links'
import { useEffect } from 'react';
import { objectToArray, getQueryParam } from 'utils/helpers';
import { useState } from 'react';

function Plan ({db, shop, history}) {
  const [plans, setPlans] = useState([])
  const [loading, setLoading] = useState(true)
  const [settingPlan, setSettingPlan] = useState(null)
  const { app: { app_plan_name }, env: { isEmbedded } } = useSelector(s => s)
  const historyReplace = history.replace
  const baseServerUrl = process.env.REACT_APP_BASE_SERVER_URL
  const featureHighlight = getQueryParam('feature')

  const dispatch = useDispatch()

  const generageCharge = useCallback(async (plan_name) => {
    try {
      setSettingPlan(plan_name)
      const { data } = await axios.post(`${baseServerUrl}/services/charges/create`, { shop, plan_name })
      const app = createApp({ apiKey: process.env.REACT_APP_SHOPIFY_API_KEY, shopOrigin: shop, })
      const { redirectUri } = data
  
      if (redirectUri && isEmbedded) {
        const redirect = Redirect.create(app)
        redirect.dispatch(Redirect.Action.REMOTE, redirectUri)
      } else if (redirectUri && !isEmbedded) {
        window.location = redirectUri
      } else {
        db.doc(`shops/${shop}`)
          .get()
          .then(s => {                                             
            dispatch({ type: 'SET_APP', payload: s.data()})
            historyReplace('/')
          })
      }
    } catch (error) {
      console.error(error)
    }
  },[db, shop, historyReplace, baseServerUrl, isEmbedded, dispatch])

  useEffect(() => {
    axios.get(`${baseServerUrl}/services/plans`).then(({data}) => {
      setPlans(objectToArray(data))
      setLoading(false)
    })
  },[baseServerUrl])

  return (
      <Page breadcrumbs={[{ content: 'Settings', url: '/settings' }]} title="Plan">
        {/* <TitleBar title="Settings / Plan"/>  */}
          <Layout>
            { loading 
            ? (
              <Layout.Section>
                <Stack distribution="fillEvenly">
                  <Card sectioned><div style={{marginBottom: '1.6rem', display: 'flex', justifyContent: 'center'}}><div style={{width: '120px'}}><SkeletonDisplayText size="large"/></div></div><SkeletonBodyText/></Card>
                  <Card sectioned><div style={{marginBottom: '1.6rem', display: 'flex', justifyContent: 'center'}}><div style={{width: '120px'}}><SkeletonDisplayText size="large"/></div></div><SkeletonBodyText/></Card>
                  <Card sectioned><div style={{marginBottom: '1.6rem', display: 'flex', justifyContent: 'center'}}><div style={{width: '120px'}}><SkeletonDisplayText size="large"/></div></div><SkeletonBodyText/></Card>
                </Stack>
              </Layout.Section>
            )
            : (
                <>
                { app_plan_name === 'VIP' && 
                    <Layout.Section>
                      <Banner status="info" title="You are a VIP">
                        You are on our VIP plan. This is an exclusive plan granted only by the {process.env.REACT_APP_APP_NAME} team. If you change your plan you will lose VIP access and that might make you sad.
                      </Banner>
                    </Layout.Section>
                }
                    <Layout.Section>
                      <div className="AC-Row Wrap Justify-Center"> 
                        { plans.map(p => {
                          var currentPlan = app_plan_name === p.plan_name
                          var isActive = p.app_features.indexOf(featureHighlight || '') > -1
                          return (
                            <div className={'AC-Col Col-12 Col-M-4 ' + (isActive ? ' AC-Feature-Card__Active' : '')} key={p.id} > 
                              <Card>
                                <Card.Section subdued>
                                  <div className="Text-Center">
                                      <DisplayText size="small">{p.plan_name}</DisplayText>
                                  </div>
                                </Card.Section>
                                <Card.Section>
                                  <div className="Text-Center">
                                    <div className="AC-Row Align-Center Justify-Center MA-0">
                                      <span>{p.price > 0 ? '$' : ''}</span>
                                      <span className="AC-Plan__Price">{p.price > 0 ? p.price : 'FREE'}</span>
                                    </div>
                                    <div>
                                      <Button 
                                        primary 
                                        onClick={() => generageCharge(p.plan_name)} 
                                        outline={currentPlan} 
                                        disabled={currentPlan || settingPlan} 
                                        loading={settingPlan === p.plan_name}
                                        >
                                        {currentPlan ? 'Current Plan' : 'Choose this plan'}
                                      </Button>
                                    </div>
                                    <div>{p.description}</div>
                                  </div>
                                </Card.Section>
                                <Card.Section title="Features">
                                  <List>
                                    { (p.feature_descriptions || []).map((f, i) => <ListItem feature={f} key={i} featureHighlight={featureHighlight}/> )}
                                  </List>
                                </Card.Section>
                              </Card>
                            </div>
                          )
                        })}
                      </div>
                    </Layout.Section>
                </>
              )
            }
            <Layout.Section>
              <PageActions secondaryActions={[ { content: 'Cancel', url: '/settings' } ]} />
            </Layout.Section>
          </Layout>
      </Page>
  )
}

const ListItem = ({feature, featureHighlight}) => {
  let highlight = featureHighlight && featureHighlight === feature.id
  return (
    <div className={`AC-Feature-Item ${highlight ? ' Active' : ''}`}>
      <div className="AC-Feature-Item__Bouncing-Icon">
        <Icon source={ArrowRightMinor} color="indigo"/>
      </div>
      { feature.id === '_previous_plan'
        ? <span>{feature.content}</span>
        : <List.Item>{feature.content}</List.Item>
      }
    </div>
  )
}


export default withFirebase(Plan)


export function PlanSection () {
  const { app_plan_name, app_plan_date, installed_first } = useSelector(s => s.app)
  
  return (
    <Layout.AnnotatedSection
      title="Plan"
      description='Manage your plan and compare the features.'
      >
        <Card >
          <Card.Section>
            <Stack distribution="fillEvenly">
              <Stack.Item>
                <div>Current Plan</div>
                <div style={{display: 'flex'}}>
                  { app_plan_name === 'VIP' && <span style={{marginRight: '0.8rem'}}><Icon source={SecureMajor} color="teal"/></span> }
                  <TextStyle variation="strong">{app_plan_name}</TextStyle>
                </div>
              </Stack.Item>
              { app_plan_date && 
                <Stack.Item>
                  <div>Current Plan Since</div>
                  <TextStyle variation="strong">{DateTime.fromMillis(app_plan_date.toMillis()).toLocaleString(DateTime.DATE_MED)}</TextStyle>
                </Stack.Item>
              }
              { installed_first && 
                <Stack.Item>
                  <div>Member since</div>
                  <TextStyle variation="strong">{DateTime.fromMillis(installed_first.toMillis()).toLocaleString(DateTime.DATE_MED)}</TextStyle> 
                </Stack.Item> 
              }
              <Stack.Item></Stack.Item>
            </Stack>
          </Card.Section>
          <Card.Section>
            <Stack distribution="trailing">
              <Button url={settingsPlanUrl}>Manage plan</Button>
            </Stack>
          </Card.Section>
        </Card>

    </Layout.AnnotatedSection>  
  )
}