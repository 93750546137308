import React, { useState, useEffect } from 'react';
import { 
  TextField,
  Icon,
  DisplayText,
  Stack,
  TextStyle,
  
 } from '@shopify/polaris';

 import {
  EditMinor
} from '@shopify/polaris-icons';
import { useCallback } from 'react';


export default function TitleEditor ({ editAcive, value, onChange }) {
  const [ active, setActive ] = useState(false)
  const [ lazyValue, setLazyValue ] = useState(value)


  useEffect(() => {
    if(!editAcive) setLazyValue(value)
  },[value, editAcive])

  const activateInput = useCallback((state) => {
    if (editAcive) setActive(true)
  }, [editAcive, setActive]) 

  const handleBlur = useCallback(() => {
    onChange(lazyValue)
    setActive(false)
    // if (editAcive) setActive(x=> !x)
  }, [onChange, setActive, lazyValue]) 

  const handleChange = useCallback(setLazyValue, [])

  if (!active) return (
    <div style={{cursor: active ? 'pointer' : 'defautl'}} onClick={activateInput}>
      <Stack alignment="center">
        { lazyValue 
          ? <DisplayText size="large" element="h1">{lazyValue}</DisplayText>
          : <TextStyle variation="subdued"><DisplayText size="large">Add title</DisplayText></TextStyle>
        }
        { editAcive && <Icon source={EditMinor} color="inkLight"/> }
      </Stack>
    </div>
  )

  return (
    <TextField 
      value={lazyValue}  
      autoFocus
      placeholder="title"
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}