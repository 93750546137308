import  './style.scss'

import { withFirebase } from '../../providers/firebase'

import React, { useState, useCallback, useMemo, useEffect } from 'react';

import { useParams } from 'react-router';

import { useSelector, useDispatch } from 'react-redux'

import { 
  Navigation,
  Frame,
  TextStyle,
  Icon,
  Stack,
 } from '@shopify/polaris';

 import {
  PinMinor,
  CirclePlusOutlineMinor,
  SettingsMajor
} from '@shopify/polaris-icons';

import Article from './Article'
import NoArticles from './NoArticles';


function Articles (props) { 
  const { db, shop, history } = props
  const { articleId } = useParams() 

  const { 
    navigation: { 
      sections,
      navigation_items = [], 
      navigation_items_unassigned,
      pinned_articles = [],
      first_article_id, 
      articles_index = {}, 
      _init 
    },
    articles
  } = useSelector(s => s)

  const article = articles[articleId]
  const defaultArticle = { html: '', title: '' }
  const isNew = articleId === 'new'
  const articleRef = articleId ? `shops/${shop}/articles/${articleId}` : null
  const historyReplace = history.replace
  const firstArticlePath = first_article_id ? `/articles/${first_article_id}` : '/articles'

  const [ activeSection, setActiveSection ] = useState( null )
  const [ mobileNavigationActive, setMobileNavigationActive ] = useState(false);
  const [ loading, setLoading ] = useState(true)
  const [ lastFetchedId, setLastFetchedId ] = useState(null)

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive(x => !x),[],);
  const handleSectionChange = useCallback(setActiveSection,[]);

  const dispatch = useDispatch()
  
  const navItems = useMemo(() => {
    const formatNavItem = (i) => {
      let isUnassigned = i.id === '__unassigned'
      let itemCount = (navigation_items_unassigned || []).length
      return { 
        ...i, 
        label: !isUnassigned ?  i.label : <TextStyle variation="subdued">{`Ungrouped articles (${itemCount})`}</TextStyle>,
        subNavigationItems: i.subNavigationItems.map(n => ({...n, label: n.label || '[add title]'})),
        onClick: () => handleSectionChange(i.id),
        url: i.id === activeSection ? '/' : null,
        ...( !isUnassigned
          ? {secondaryAction: { url: `/articles/new?section=${i.id}`, icon: CirclePlusOutlineMinor, }}
          : {}
        )
      }
    }

    return [
      ...navigation_items.map(formatNavItem),
      ...( navigation_items_unassigned 
          ? navigation_items_unassigned.map(formatNavItem)
          : []
          )
      ]
  },[navigation_items, navigation_items_unassigned, activeSection, handleSectionChange])

  useEffect(() => {
    if (articleId && articles_index[articleId]) {
      let sectionId = articles_index[articleId].section
      sectionId = sections[sectionId] ? sectionId : '__unassgined'
      setActiveSection(sectionId)
    } 
  },[articleId, articles_index, sections])

  useEffect(() => {
    if (isNew) setLoading(false)
    if (articleId && _init && articles_index[articleId] && !isNew) {               
      if (lastFetchedId !== articleId)  { 
        window.scrollTo(0, 0)       
        setLoading(!article)
        setLastFetchedId(articleId)
        db.doc(articleRef).get().then(s => {
          let a = s.data() || {}                 
          setLoading(false) 
          dispatch({ type: 'SET_ARTICLE', payload: { ...a, id: s.id } })
        })
      } 
      // else {
      //   setLoading(false)
      // }
      
    } else if (_init && !isNew && firstArticlePath !== window.location.pathname) {      
      historyReplace(firstArticlePath)
    }
  },[ db, article, articleRef, articleId, isNew, lastFetchedId, _init, articles_index, historyReplace, firstArticlePath, dispatch, defaultArticle ])

 
    return (
      <div className={`AC-Article__Wrap ${articleId ? ' Article--Active' : ''}`}>
        <Frame
          navigation={<NavigationMarkup items={navItems} pinnedItems={pinned_articles}/>}
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={toggleMobileNavigationActive}
        >
          { articleId 
            ? <Article 
                key={articleId}
                article={(isNew ? defaultArticle : article) || defaultArticle}
                articleId={articleId}
                articleRef={articleRef}
                loading={loading}
                isNew={isNew}
                firstArticlePath={firstArticlePath}
                onActivateMobileNavigation={toggleMobileNavigationActive}
                historyReplace={historyReplace}
                />
            : <NoArticles/>
          }
        </Frame>
      </div> 
    );
  }
  
const NavigationMarkup = ({ items, pinnedItems }) => {  
  return (
    <Navigation location="/">
    { pinnedItems.length > 0 &&
      <Navigation.Section
        title={<Stack alignment="center"><Icon source={PinMinor} color="inkLightest"/> <span>Pinned</span></Stack>}
        items={pinnedItems}
      />
    }
      <Navigation.Section
        separator={pinnedItems.length > 0}
        items={items}
      />
      <div style={{height: '100%'}}></div>
      <Navigation.Section
        items={[{ label: 'Settings', icon: SettingsMajor, url: '/settings' }]}
      />
      
    </Navigation>
  )
};



export default withFirebase(Articles)
