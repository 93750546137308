import React from 'react'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

import axios from 'axios'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(config)

const FirebaseContext = React.createContext(null)
const db = firebase.firestore()
const firestore = firebase.firestore
const auth = firebase.auth()
const storage = firebase.storage()

export const withFirebase = Component => props => {
  const graphql = async (query, variables, shop) => {
    try {
      const token = await firebase.auth().currentUser.getIdToken()
    
      const { data } = await axios({
        url: `${process.env.REACT_APP_BASE_SERVER_URL_STATIC}/api/graphql`,
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token
        },
        data: {
          shop: shop,
          query: query,
          variables: variables,
        }
      })
  
      return data
    } catch (error) {
      console.error(error)      
    }
  }
  
  
  return (
    <FirebaseContext.Consumer>
          {
            ({ shop, uid, authUser, authShop }) => { 
              return (
                <Component 
                  {...props} 
                  firebase={firebase} 
                  db={db}
                  firestore={firestore}
                  // firestoreTimestamp={firestore.FieldValue.serverTimestamp()}
                  storage={storage}
                  auth={auth}
                  shop={shop}
                  uid={uid}
                  graphql={(query, variables) => graphql(query, variables, shop)}
                  authState={(authUser !== null && authShop !== null) ? (authUser && authShop === shop) : null }
                  authUser={authUser}
                />
              )
            }
          }
      </FirebaseContext.Consumer>
  )
}


export default FirebaseContext

export { firebase, db, firestore, auth, storage }