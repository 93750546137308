import './style.scss'

import React from 'react'


import { 
  Spinner
} from "@shopify/polaris";

export default function Loader ({ major, color, centered = true, message = 'Loading' }) {

  return (
    <>
    { major 
      ? (
        <div className={centered ? 'ta-loader-wrap ta-center-screen' : 'ta-loader-wrap '}>
          {color && <style>{'body { background-color: ' + color + ' }'}</style>}
          <h1>{message}</h1>
            <div className="svg-container">
              <svg viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="40" />
                <circle cx="50" cy="50" r="40" />
                <circle cx="50" cy="50" r="40" />
                <circle cx="50" cy="50" r="40" />
              </svg>
            </div>
        </div>
      )
      : (
          // <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
          <div className={centered ? 'ta-loader-wrap ta-center-screen' : 'ta-loader-wrap '}>

            {color && <style>{'body { background-color: ' + color + ' }'}</style>}
            <Spinner accessibilityLabel="authenticating.." size="large" color="teal" />
          </div>
      )
    }
    </>
  )
}