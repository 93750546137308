import { storage } from 'providers/firebase'

export const compareHtmlAndRemoveImages = (oldHtml, newHtml) => {
  try {
    let parser = new DOMParser()
    let oldDoc = parser.parseFromString(oldHtml, "text/html")
    let newDoc = parser.parseFromString(newHtml, "text/html")
    let oldImages = oldDoc.getElementsByTagName('img')
    let newImages = newDoc.getElementsByTagName('img')
    let oldImageSrcs = []
    let newImageSrcs = []
    for (const img of oldImages) oldImageSrcs.push(img.src)
    for (const img of newImages) newImageSrcs.push(img.src)
    
    let imagesToRemove = oldImageSrcs.filter(src => !newImageSrcs.includes(src))
    imagesToRemove.forEach((url) => {
      try {
        storage.refFromURL(url).delete().then(() =>  console.log('removed', url))
      } catch (_error) {}
    })
  } catch (_error) {}
}


export const removeImagesNotInHtml = (html, images) => {

  try {
    let parser = new DOMParser()
    let doc = parser.parseFromString(html, "text/html")
    let docImages = doc.getElementsByTagName('img')
    let imageSrcs = []
    for (const img of docImages) imageSrcs.push(img.src)
    
    let imagesToRemove = images.filter(src => !imageSrcs.includes(src))

    imagesToRemove.forEach((url) => {
      try {
        storage.refFromURL(url).delete().then(() =>  console.log('removed', url))
      } catch (_error) {}
    })
  } catch (_error) {}
}