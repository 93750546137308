import { useLayoutEffect, useState } from 'react';

export default function useWindowSize() {
  const [size, setSize] = useState({ window: { width: 0, height: 0 }, document: { width: 0, height: 0 }});
  useLayoutEffect(() => {
    function updateSize() {
      setSize({
        window: { width: window.innerWidth, height: window.innerHeight },
        document: { width: document.documentElement.clientWidth, height: document.documentElement.clientHeight }
      });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}
