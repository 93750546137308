import { combineReducers } from "redux";
import { 
  app, 
  config, 
  env,
  articles,
  navigation,
  admin,
 } from "./states";

const reducer = combineReducers({
  app, 
  config, 
  env,
  articles,
  navigation,
  admin,
});

export default reducer;

