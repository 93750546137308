import React from 'react';


import { 
  AppProvider,
  Card,
  SkeletonBodyText,
  Spinner,
  Tabs,
  SkeletonPage,
  Page,
  Layout,
  TextContainer,
  SkeletonDisplayText,
 } from '@shopify/polaris';
 

 const Word = ({width}) => <div style={{width: width}}><SkeletonBodyText lines={1} /></div>

 const CardSpinner = () => <div className="AC-Tabbed-Card__Loader-Wrap"><Spinner size="large" color="teal"/></div>
 
 const Skeleton = ({children}) => {
   return (
     <AppProvider>
      <div className="AC-Skeleton_Fullwidth-Page">
        <SkeletonPage>
          <Card>
            <Tabs tabs={[{id: 0, content: <Word width={120}/>}, {id: 1, content: <Word width={120}/>}]} selected={0}></Tabs>
            <CardSpinner/>
          </Card>
        </SkeletonPage>
      </div>
    </AppProvider>
  )
}


Skeleton.Article = () => {
  return (
    <AppProvider>
      <Page>
      {/* <Loading/> */}
        <Layout>
          <Layout.Section>
             <TextContainer>
              <SkeletonDisplayText size="extraLarge" />
              <div style={{marginTop: '3.5rem'}}>
                <SkeletonBodyText lines={9} />
              </div>
            </TextContainer>
          </Layout.Section>
        </Layout>
      </Page>
    </AppProvider>
  )
}

export default Skeleton

