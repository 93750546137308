import './style.scss'

import { withFirebase } from '../../providers/firebase'

import React from "react"

import { useSelector } from 'react-redux'


import {
  Layout,
  Scrollable,
  Card,
  Stack,
  TextStyle,
  Heading,
  ResourceItem,
  Button,
  Icon,
  Sheet,
  TextField
} from "@shopify/polaris";

import {
  DragHandleMinor,
  PlusMinor,
  MobileCancelMajor,
} from '@shopify/polaris-icons';

import * as icons from '@shopify/polaris-icons';

import uniqid from 'uniqid'

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { objectToArray, arrayMove, arrayToObject } from 'utils/helpers';
import { useCallback } from 'react';
import { useState } from 'react';
import DeleteButton from 'components/DeleteButton';
import { useEffect } from 'react';
import { useMemo } from 'react';

function Settings (props) {
  const { db, shop } = props
  const [ editId, setEditId ] = useState(null);
  const { navigation: { sections = {} } } = useSelector(s => s)

  const [ lazySections, setLazySections ] = useState(sections)

  const dbRef = `shops/${shop}/settings/navigation`

  const handleDragEnd = useCallback((e) => {
    let arr = objectToArray(lazySections)
    let sections = arrayToObject(arrayMove(arr, e.source.index, e.destination.index))
    setLazySections(sections)
    db.doc(dbRef).set({ sections }, { merge: true})
  },[db, dbRef, lazySections])

  const closeEditor = useCallback((e) => setEditId(null),[])

  const editSection = useCallback(setEditId,[])

  const handleSave = useCallback((passedSections) => {
    let sections = passedSections || lazySections
    db.doc(dbRef).set({ sections }, { merge: true})
  },[db, dbRef, lazySections])
  
  const handleChange = useCallback((section, save) => {
    let _sections = { ...sections, [editId]: section }
    setLazySections(_sections)
    if (save) handleSave(_sections)
  }, [sections, handleSave, editId])

  const handleAddSection = useCallback(() => setEditId(uniqid()),[])

  const handleDeleteSection = useCallback(() => {
    let _sections = { ...sections }
    delete _sections[editId]
    setLazySections(_sections)
    console.log(_sections);
    db.doc(dbRef).update({ sections: _sections })
    setEditId(null)
    
  },[db, dbRef, sections, editId])

  const RenderItem = (item) => {
    const {id, onClick, label, icon} = item;
    return (
      <div className="Polaris-ResourceList__ItemWrapper">
        <ResourceItem id={id}>
          <Stack alignment="center">
          <Icon source={DragHandleMinor} color="subdued"/>
          { icon && <Icon source={icons[icon]} color="base"/> }
            <Stack.Item fill>
              <TextStyle variation="strong">{label}</TextStyle>
            </Stack.Item>
            <Button onClick={onClick}>Edit</Button>
          </Stack>
        </ResourceItem>

      </div>
    );
  }

  return (
    <Layout.AnnotatedSection
      title="Sections"
      description="Sections group articles in your guide. Drag to reorder and edit to change title and icon."
      >
        <Card>
          <div className="Polaris-ResourceList">
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable">
                {(droppableProvided, droppableSnapshot) => (
                    <div ref={droppableProvided.innerRef}>
                      {objectToArray(lazySections).map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(draggableProvided, draggableSnapshot) => (
                            <div className="Polaris-ResourceList__ItemWrapper"
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                             >
                              <RenderItem {...item} onClick={() => editSection(item.id)}/>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
          </div>
        </Card>
        <div style={{margin: '2rem 0'}}>
          <Stack distribution="trailing">
            <Button icon={PlusMinor} onClick={handleAddSection}>Add</Button>
          </Stack>
        </div>

        <SectionEditor 
          active={Boolean(editId)} 
          onClose={closeEditor} 
          section={lazySections[editId]} 
          onSave={handleSave}
          onChange={handleChange}
          onDelete={handleDeleteSection}
          sectionsLength={Object.keys(sections || {}).length}
          // canDelete={Object.keys(sections || {}).length > 1}
        />

    </Layout.AnnotatedSection>
    
  )
}

const SectionEditor = ({active, onClose, onSave, onChange, onDelete, section = {}, sectionsLength }) => {
  const excludeIcons = []
  const iconList = Object.keys(icons).filter(i => (!excludeIcons.includes(i) && i.indexOf('Twotone') === -1&& i.indexOf('Minor') === -1))

  const handleChange = useCallback((val, key, save) => {
    let s = { ...section, [key]: val }
    onChange(s, save)
  },[onChange, section]) 
  
  useEffect(() => {    
    if (active && section.index === undefined) {
      handleChange(sectionsLength, 'index')
    }
  },[active, section, sectionsLength, handleChange])


  const canDelete = useMemo(() => sectionsLength > -1,[sectionsLength])

  return (
    <Sheet open={active} onClose={onClose}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%',}}>
        <div style={{ alignItems: 'center', borderBottom: '1px solid #DFE3E8', display: 'flex', justifyContent: 'space-between', padding: '1.6rem', width: '100%',}}>
          { section.label ? <Heading>{section.label || ''}</Heading> : <Heading><TextStyle variation="subdued">title</TextStyle></Heading> }
          <Button icon={MobileCancelMajor} onClick={onClose} plain/>
        </div>
        <Scrollable style={{padding: '1.6rem', height: '100%'}}>
          <TextField
            label="Section title"
            placeholder="enter title"
            value={section.label}
            onChange={(v) => handleChange(v, 'label')}
            onBlur={() => onSave()}
            />
          <div className="AC-Icon-Picker">
            <Stack alignment="center">
              <Stack.Item fill>
                <label className="Polaris-Label__Text" style={{marginBottom: '1rem'}}>Icon</label>
              </Stack.Item>
                <Button plain onClick={() => handleChange(null, 'icon')}>Clear</Button>
            </Stack>
            <Stack distribution="equalSpacing" alignment="leading">
              {iconList.map(i => (
                <SectionIcon 
                  key={i}  
                  id={i} 
                  active={section.icon === i} 
                  onSelect={(v) => handleChange(v, 'icon', true)}
                />
              ))}
            </Stack>
          </div>
        </Scrollable>
        <div style={{ alignItems: 'center', borderTop: '1px solid #DFE3E8', display: 'flex', justifyContent: 'space-between', padding: '1.6rem', width: '100%', }}>
          { canDelete ? <DeleteButton destructive plain onDelete={onDelete}/> : <div></div> }
          <Button primary onClick={onClose}> Done</Button>
        </div>
      </div>
    </Sheet>
  )
}

const SectionIcon = ({ id, active, onSelect }) => {

  const handleSelect = useCallback(() => onSelect(id),[id, onSelect])
  const icon = icons[id]

  return (
    <>
      { active 
        ? <Button primary icon={icon}></Button>
        : <div 
            onClick={handleSelect} 
            className="AC-Icon-Picker__Icon"
          >
            <Icon source={icon} color={active ? 'indigo' : 'inkLight'}/>
          </div>
      }
    </>
  )
}

export default withFirebase(Settings)