import { objectToArray } from "utils/helpers";
import * as icons from '@shopify/polaris-icons';

const appDefault = { app_features: {}, _init: false }
export function app (state = appDefault, action) {
  switch (action.type) {
    case 'SET_APP': {
      let payload = action.payload || {}
      var app_installed  = (action.payload || {}).app_installed || false 
      var app_features = {};
      (payload.app_features || []).forEach(f => {app_features[f] = true})
      return { ...payload || appDefault, _init: true, app_installed, app_features}
      // set app_installed to false if it does not exist. This is because app_inttalled is used to signout the user if they are signed in and the app is not installed.
    }
    case 'RESET_INIT': {
      return appDefault
    }
    default: {
      return state;
    }
  }
};


const configDefault = { _init: false }
export function config (state = configDefault, action) {
 
  switch (action.type) {
    case 'SET_CONFIG': {
      return {...action.payload, _init: true }
    }
    case 'UPDATE_CONFIG': {
      return {...state,  ...action.payload,}
    }
    case 'RESET_INIT': {
      return configDefault
    }
    default: {
      return state;
    }
  }
};

const navigationDefault = { navigation_items: [], articles_index: {}, _init: false }

const articleIndexToNav = (a) => ({ ...a, label: a.title, url: `/articles/${a.id}`})

function buildNavigation (sections, articles_index) {
  let navigation_items_unassigned = null
  let articles = Object.values(articles_index).map(articleIndexToNav)
  let unassignedArticles = articles.map(a => a.id)
  let sortIndex = (a,b) => a.index > b.index ? 1 : -1
  let sortTitle = (a,b) => a.title > b.title ? 1 : -1

  let navigation_items = objectToArray(sections)
    .map(s => ({
      ...s,
      icon: s.icon ? icons[s.icon] : null,
      subNavigationItems: articles
        .filter(a => a.section === s.id)
        .sort(sortTitle)
        .map(a => { unassignedArticles.splice(unassignedArticles.indexOf(a.id), 1); return a })
    }))
    .sort(sortIndex)
    
    if (unassignedArticles.length > 0 ) {
      navigation_items_unassigned = [{
        id: '__unassigned',
        subNavigationItems: unassignedArticles.map(id => articleIndexToNav(articles_index[id]))
      }]
    }

    let pinned_articles = articles.filter(a => a.pinned)

    return { navigation_items, navigation_items_unassigned, pinned_articles }

}

export function navigation (state = navigationDefault, action) {
  
  switch (action.type) {
    case 'SET_NAVIGATION': {
      const { payload: { sections = {}, articles_index = {} } = {} } = action

      const nav = buildNavigation(sections, articles_index)
      const { navigation_items } = nav
      let sectionsWithItems = navigation_items.filter(i => i.subNavigationItems.length > 0)
      let first_article_id = sectionsWithItems.length > 0 ? sectionsWithItems[0].subNavigationItems[0].id : null
      
      return { 
        ...state, 
        ...nav,
        sections,
        sections_list: objectToArray(sections),
        first_article_id, 
        articles_index,
         _init: true 
        }
    }
    case 'SET_ARTICLE_INDEX': {
      let { sections } = state
      let articles_index = { ...state.articles_index, [action.payload.id]: action.payload }      

      return { ...state, ...buildNavigation(sections, articles_index), articles_index }
    }
    case 'DELETE_ARTICLE_INDEX': {
      let { sections } = state
      let articles_index = { ...state.articles_index }
      delete articles_index[action.payload.id]

      return { ...state, ...buildNavigation(sections, articles_index), articles_index }
    }
    
    default: {
      return state;
    }
  }
};

export function articles ( state = {}, action ) {
  switch (action.type) {
    case 'SET_ARTICLE': {
      let article = action.payload      
      return { ...state, [article.id]: article }
    }
    default: {
      return state;
    }
  }
}

export function env (state = { isAgent : false, environment: 'production' }, action) {
 
  switch (action.type) {
    case 'SET_AGENT': {
      return { ...state, isAgent: action.payload }
    }
    case 'SET_AGENT_ROLE': {
      return { ...state, agentRole: action.payload }
    }
    case 'SET_SHOP': {      
      return { ...state, shop: action.payload }
    }
    case 'SET_EMBEDDED': {      
      return { ...state, isEmbedded: action.payload }
    }
    case 'SET_ENVIRONMENT': {
      let environment = action.payload
      return { ...state, environment, isDev: environment === 'development', isProd: environment === 'production' }
    }
    default: {
      return state;
    }
  }
};


export function admin (state = { }, action) {
 
  switch (action.type) {
    case 'SET_SHOPS': {
      return { ...state, shops: action.payload }
    }
    case 'SET_ACTIVE_SHOPS': {
      return { ...state, active_shops: action.payload }
    }
    case 'SET_INACTIVE_SHOPS': {
      return { ...state, inactive_shops: action.payload }
    }
    default: {
      return state;
    }
  }
};

