import React from 'react';
import {withRouter} from 'react-router';

import {RoutePropagator} from '@shopify/react-shopify-app-route-propagator';
import {Context as AppBridgeContext} from '@shopify/app-bridge-react';

export default withRouter(function Routes({
  location,
  server,
}) {
  const app = React.useContext(AppBridgeContext);

  return !server && app && location ? (
    <RoutePropagator location={location} app={app} />
  ) : null;
});