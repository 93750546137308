import { withFirebase } from 'providers/firebase'

import React, { useEffect, useState } from "react"

import { useSelector, useDispatch } from 'react-redux'

import { Provider as AppBridgeProvider } from '@shopify/app-bridge-react';
import enTranslations from '@shopify/polaris/locales/en.json';

import { AppProvider, Banner, Frame, Loading } from "@shopify/polaris";

import { requestAuth } from "utils/auth"
import { getQueryParam } from "utils/helpers"
import { refreshAuthPath } from 'utils/links'


import Skeleton from 'layout/app/Skeleton'

import store from 'store-js'
import Loader from 'components/Loader';

function Callback (props) {
  const {shop, auth, history} = props
  const [error, setError] = useState(false)
  const historyReplace = history.replace
  const isEmbeded = window.top !== window.self

  const dispatch = useDispatch()
  const { isDev } = useSelector(s => s.env)

  const config = { apiKey: process.env.REACT_APP_SHOPIFY_API_KEY, shopOrigin: shop, forceRedirect: true}
  
  
  useEffect(() => {
    dispatch({ type: 'SET_SHOP', payload: shop })
  },[shop, dispatch])

  useEffect(() => {    
    setError(false)
    if ((isEmbeded || isDev) && shop) {
      const token = getQueryParam('fb_token')
      const redirect = store.get('auth_redirect')
      
      if (token) {
        
        auth.signInWithCustomToken(token).catch(error => {
          console.error(error)
          setError(true)
        })
        
        let authWatch = auth.onAuthStateChanged((user) => {                    
          if (user) {            
            auth.currentUser.getIdTokenResult()
            .then((token) => {          
              if (token.claims.shop) {
                historyReplace(`${redirect || '/'}${window.location.search}`)
                authWatch()
                }
              })
          } 
        })
      } else {
        requestAuth(shop)
      }

    }

  },[shop, setError, auth, historyReplace, dispatch, isEmbeded, isDev])
 
  if (!isEmbeded && !isDev) return <AppProvider><AppBridgeProvider config={config}  i18n={enTranslations}><Loader major message="Signing in"/></AppBridgeProvider></AppProvider>

  return (
    <AppProvider>
      { !error //&& isEmbeded
        ? isEmbeded 
          ? <Skeleton/>
          : <Frame><Loading/></Frame>
        : (
          <div className="AC-Auth-Error__Wrapper"> 
            <Banner title="Error signing in" status="critical" action={{content: 'Retry', url: refreshAuthPath}}>
              <p>There was a problem signing you in. Retry and if the problem persists try reinstalling app</p>
            </Banner>
          </div>
        )
      }
    </AppProvider>
  )
}

export default withFirebase(Callback)