import React from 'react';

import { NavLink } from "react-router-dom";


export const AdapterLink = ({ url, external, ...rest}) => {
  return  url.substr(0,1) === '/' 
    ? <NavLink to={url} {...rest} exact activeClassName="Polaris-Navigation__Item--selected" />
    : <a href={url} target={external ? '_blank' : ''} {...rest}>{rest.children}</a>
}

