import React from "react"

import { 
  AppProvider,
  Layout,
  Banner,
  Page,
} from "@shopify/polaris";

import { helpNewTicketUrl } from 'utils/links'
  
export default function ChargeDeclined (props) {

    return (
      <AppProvider>
        <Page>
          <Layout>
            <Layout.Section>
            <Banner title="Charge declined" status="critical" action={{content: 'Get help', url: helpNewTicketUrl, external: true}}>
              Your charge was declined. If this was not intentional please try reinstalling the app. 
              <br/>
              If the problem persists please contact support. 
            </Banner>
            </Layout.Section>
          </Layout>
        </Page>
      </AppProvider>
    )
}
