export function objectToArray (obj, index = 'index') {
  if (Object.keys(obj || {}).length === 0 ) return []

  var arr = []  
  var keys = Object.keys(obj)

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const element = obj[key]
    
    if (typeof element !== 'undefined') {
      element.id = key
      arr.push(element)
    }
  }

  var compare = (a, b) => {
    if (a[index] < b[index]) return -1
    if (a[index] > b[index]) return 1
    return 0
  }
  return arr.sort(compare)
}

export function sortArray(arr, index = 'index' ) {
  if (!Array.isArray(arr)) return []
  var compare = (a, b) => {
    if (a[index] < b[index]) return -1
    if (a[index] > b[index]) return 1
    return 0
  }
  return arr.sort(compare)
}

export function arrayToObject (arr = []) {
  if (arr.length === 0 ) return {}

  var obj = {}

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]
    const id = item.id || i
    obj[id] = { ...item, index: i }
    
  }
  return obj
}

export function isValue (value) {
  return (value !== undefined && value !== null && value !== '')
}

export function newTags (tags, tag, add) {
  tags = (tags || '').length > 0 ? tags.split(',') : []
  if (add) {
    tags.push(tag)
    return tags.join(',')
  } else {
    tags.splice(tags.indexOf(tag), 1)          
    return tags.join(',')
  }
}

const cleanTags = (tags) => {
  if (!Array.isArray(tags)) tags = (tags || '').split(',')
  return [ ...tags ]
}

export const modifyTags = {
  add: (tags, tag) => {
    tags = cleanTags(tags)
    
    if (tag && !tags.includes(tag)) {
      tags.push(tag)
    }
    
    return tags
  },
  remove: (tags, tag) => {
    tags = cleanTags(tags)
    let index = tags.indexOf(tag)
    
    if (tag && index > -1) {
      tags.splice(index, 1)
    }
    
    return tags
  },
  replace: (tags, oldTag, tag) => {
    tags = cleanTags(tags)
    let index = tags.indexOf(oldTag)
    
    if (tag && index > -1) {
      tags.splice(index, 1, tag)
    } else if (tag) {
      tags.push(tag)
    } else if (!tag && index > -1) {
      tags.splice(index, 1)
    }
    
    return tags
  }
}

function getFormattedDate(date, prefomattedDate = false, hideYear = false) {  
  const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  
  function pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }
  
  const day = date.getDate();
  const month = MONTH_NAMES[date.getMonth()];
  const year = date.getFullYear();
  const fullhours = date.getHours();  
  const hours = fullhours === 0 ? 12 : fullhours > 12 ? pad(fullhours - 12) : pad(fullhours)
  const ap = fullhours > 11 ? 'pm' : 'am'
  let minutes = date.getMinutes();
  
  if (minutes < 10) {
    // Adding leading zero to minutes
    minutes = `0${ minutes }`;
  }
  
  if (prefomattedDate) {
    // Today at 10:20
    // Yesterday at 10:20
    if (prefomattedDate === 'Today') return `${ hours }:${ minutes } ${ ap }`
    return `${ prefomattedDate } at ${ hours }:${ minutes } ${ ap }`;
  }
  
  if (hideYear) {
    // 10 January at 10:20
    return `${ day } ${ month.substring(0, 3) }`// at ${ hours }:${ minutes } ${ ap }`;
  }
  console.log(day , month, year)
  // 10 January 2017 at 10:20
  return `${ day } ${ month.substring(0, 3) } ${ year }`// at ${ hours }:${ minutes } ${ ap }`;
  
}


// --- Main function
export function timeAgo(dateParam) {
  if (!dateParam) return null;
  if (typeof dateParam === 'string' && dateParam.toLowerCase() === 'never') return dateParam;

  const date = typeof dateParam === 'object' 
    ? dateParam 
    : dateParam.toString().length === 10
      ? new Date(dateParam * 1000)
      : new Date(dateParam); 
  
  const DAY_NAMES = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const today = new Date();
  const yesterday = new Date(today - DAY_IN_MS);
  const seconds = Math.round((today - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const dayName = DAY_NAMES[date.getDay()]
  const daysAgo = Math.round(Math.abs((today - date) / DAY_IN_MS))
  const isToday = today.toDateString() === date.toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();
  const isThisYear = today.getFullYear() === date.getFullYear();
  
  if (seconds < 5) {
    return 'now';
  } else if (seconds < 60) {
    return `${ seconds } seconds ago`;
  } else if (seconds < 90) {
    return 'about a minute ago';
  } else if (minutes < 60) {
    return `${ minutes } minutes ago`;
  } else if (isToday) {
    return getFormattedDate(date, 'Today'); // Today at 10:20
  } else if (isYesterday) {
    return getFormattedDate(date, 'Yesterday'); // Yesterday at 10:20
  } else if (daysAgo < 7) {
    return getFormattedDate(date, dayName, true); // Tuesday at 10:20
  } else if (isThisYear) {
    return getFormattedDate(date, false, true); // 10 January at 10:20
  }

  return getFormattedDate(date); // 10 January 2017. at 10:20
}


export function cleanPhone (countryCode, phone) {
  let ccLen = countryCode.length
  let pLen = phone.length
  phone = phone.substr(0 , ccLen) === countryCode ? phone.substr(ccLen, pLen - ccLen) : phone
  return phone.substr(0, 10)
} 

export function validPhoneNumber (countryCode, phone) {
  let number = Number(phone.substr(0,1)) === 0 ? phone.substr(1, phone.length - 1) : phone
  return  { phoneNumber: `${countryCode}${number}`, valid: number.length >= 9 }
}

export async function copyToClipboard (text) {

    async function fallbackCopyTextToClipboard(text) {
      var successful = false
      var textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.style.position= "fixed";  //avoid scrolling to bottom
      textArea.style.left = '-9999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
    
      try {
        document.execCommand('copy');
        successful = true
      } catch (err) {
        successful = false
      }

      document.body.removeChild(textArea);
      return successful 
    }

    if (!navigator.clipboard) return fallbackCopyTextToClipboard(text)
    
    await navigator.clipboard.writeText(text)
    return true
}

export function templateString (template, data = {}) {
  if (typeof template !== 'string') return ''  
  function interpolate(str) {
    return function interpolate(o) {
        return str.replace(/{{([^{}]*)}}/g, function (a, b) {
            var r = o[b];
            return typeof r === 'string' || typeof r === 'number' ? r : a;
        });
    }
  }
  return interpolate(template)(data)
}


export function safeId (string) {
  return string.replace(/[^a-z0-9]/g, (s) => {
      var c = s.charCodeAt(0);
      if (c === 32) return '_';
      if (c >= 65 && c <= 90) return s.toLowerCase();
      return ''
  });
}

export function arrayMove(arr, oldIndex, newIndex) {
  while (oldIndex < 0) {
      oldIndex += arr.length;
  }
  while (newIndex < 0) {
      newIndex += arr.length;
  }
  if (newIndex >= arr.length) {
      var k = newIndex - arr.length + 1;
      while (k--) {
          arr.push(undefined);
      }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr
};

export function getQueryParam (param) {
  let location = window.location
  const query = new URLSearchParams(location.search);
  if (typeof param === 'string') {
    return query.get(param)
  } else if (Array.isArray(param)) {
    return param.map(p => query.get(p))
  } else {
    return query.get(param)
  }
}



export function exportToJsonFile (jsonData, name = 'data') {
  name = name.split('.')[0]
  let dataStr = JSON.stringify(jsonData)
  let dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr)

  let exportFileDefaultName = `${name}.json`

  let linkElement = document.createElement('a')
  linkElement.setAttribute('href', dataUri)
  linkElement.setAttribute('download', exportFileDefaultName)
  linkElement.click()
}

export function importFromJsonFile (file) {
  return new Promise((resolve, reject) => {
    var reader  = new FileReader()
    reader.onload = receivedText
    reader.readAsText(file)

    function receivedText (e) {
      let lines = e.target.result
      var newArr = JSON.parse(lines)
      resolve(newArr)
    }
  })
}


export function testPath ({test, path, target}) {

  function comparePart (testPart, pathPart) {
    let extract = /(.*){{([A-Z a-z 0-9 _-]*)}}(.*)/g.exec( testPart )
    if (extract) {
      let preText = extract[1]
      let key = extract[2]
      let postText = extract[3]
      let valueStart = preText.length
      let valueLength = pathPart.length - valueStart - postText.length
      let value = pathPart.substr(valueStart, valueLength)
      let match = pathPart.startsWith(preText) && pathPart.endsWith(postText)
      return { match, data: { [key]: value } }
    } else {
      return { match: testPart === pathPart }
    }
  }

  if (path.substring(0,1) !== '/') path = `/${path}`
  if (test.substring(0,1) !== '/') test = `/${test}`
  if (target.substring(0,1) !== '/') target = `/${target}` 
  
  var pathParts = path.split('/')
  var testParts = test.split('/')
  var pathData = {}

  if (pathParts.length !== testParts.length) return null
  
  var match = testParts
    .map((testPart, i) => {
      let { match, data } = comparePart(testPart, pathParts[i])
      if (data) pathData = { ...pathData, ...data }
      return match
    })
    .every(x => x)
  
  return match ? templateString(target, pathData) : null
}

export function mapQuerySnapshot (querySnapshot) {
  return querySnapshot.docs.map(d => ({...d.data() || {}, id: d.id }))
}

export function paginateArray(array, size, cursor) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((cursor - 1) * size, cursor * size);
}