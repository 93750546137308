import './style.scss'

import { withFirebase } from '../../providers/firebase'

import React, { useEffect, useState } from "react"

import { useSelector } from 'react-redux'

import { AppProvider, Banner, Frame, Loading } from "@shopify/polaris";

import store from 'store-js'

import { requestAuth, requestTestAuth } from "utils/auth"
import { getQueryParam } from "utils/helpers"

import Skeleton from 'layout/app/Skeleton'
import { refreshAuthPath } from 'utils/links'



function Auth (props) {
  const {shop, auth, history} = props
  const [error, setError] = useState(false)
  const historyReplace = history.replace
  const pathname = window.location.pathname

  const { env: { isProd, isDev, isEmbeded } } = useSelector(s => s)

  
  useEffect(() => {
    const redirect = getQueryParam('redirect')

    if (redirect) {
      store.set('auth_redirect', redirect)
    } else {
      store.remove('auth_redirect')
    }    
    // if ( authTries < 5 ) {
      if (isProd) {
        requestAuth(shop).catch(error => {                    
          setError(true)
        })
      } else if (isDev) {
        requestTestAuth(shop, historyReplace).catch(error => setError(true))
      }
      // dispatch({type: 'INCRIMENT_AUTH_TRY'})
    // } else {
    //   console.error('ATTEMPTED TO SIGN IN TOO MANY TIMES');
    //   setError(true)
    // }

  },[shop, auth, pathname, isProd, historyReplace, isDev])

  return (
    <AppProvider>
        { !error 
          ? isEmbeded 
            ? <Skeleton/>
            : <Frame><Loading/></Frame>
          : (
           <div className="AC-Auth-Error__Wrapper"> 
            <Banner title="Error signing in" status="critical" action={{content: 'Retry', url: refreshAuthPath}}>
              <p>There was a problem signing you in. Retry and if the problem persists try reinstalling app</p>
            </Banner>
           </div>
          )
        }
    </AppProvider>
  )
}

export default withFirebase(Auth)