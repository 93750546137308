import './style.scss'

import { withFirebase } from '../../providers/firebase'

import React from "react"



import {
  Layout,
  Page,
  Banner,
  Badge
} from "@shopify/polaris";

// import { TitleBar } from '@shopify/app-bridge-react';


import { PlanSection } from './plan/Plan'
import Sections from './Sections'

function Settings (props) {
  // const [loading, setLoading] = useState(false)

  return (
    <Page title="Settings" separator breadcrumbs={[{content: 'Guide', url: '/'}]}>
      {/* <TitleBar title="Settings" />  */}
          <Layout>   
          {/* <Layout.Section>
              <Breadcrumbs breadcrumbs={[{ content: 'Guide', url: '/' }]}/>
            </Layout.Section> */}
            <PlanSection/> 
            <Sections/> 
            <Layout.AnnotatedSection
              title="Mentions"
              description="Link to articles in your guide and resources in Shopify admin using mentions."
              >
                <table className="AC-Mention-Table">
                  <tbody>
                  <tr><td><b>#</b></td><td><b>[article name]</b> - another article in your Handbook</td></tr>
                  {/* <tr><td><b>@</b></td><td><b>[customer name]</b> - a customer in your store</td></tr> */}
                  <tr><td><b>@</b></td><td><b>[page name]</b> - a page in your store</td></tr>
                  <tr><td><b>+</b></td><td><b>[collection name]</b> - a collection in your store</td></tr>
                  </tbody>
                </table>
              <Banner title="Hint" status="info">
                <p>To mention an article or resource, start typing the mention character followed by it's name. For example:</p>
                <p style={{marginTop: '4px'}}>To link the article <b>Getting Started</b>, start typing <Badge>#gett</Badge> and select from suggestions</p>
                {/* <p style={{marginTop: '4px'}}>To link the customer <b>Jane Smith</b>, start typing <Badge>@Jan</Badge> and select from suggestions</p> */}
                <p style={{marginTop: '4px'}}>To link the page <b>About Us</b>, start typing <Badge>@</Badge> and select from suggestions</p>
                <p style={{marginTop: '4px'}}>To link the page <b>Coffee</b>, start typing <Badge>+</Badge> and select from suggestions</p>
             
              </Banner>
            </Layout.AnnotatedSection>
          </Layout>
    </Page>
    
  )
}

export default withFirebase(Settings)