import React from 'react';

import {
  Redirect,
  Switch,
  Route,
} from "react-router-dom";


import Shops from '../../views/admin/shops'
import Shop from '../../views/admin/shop'

// import AppWrapper from './AppWrapper'


export const Routes = () => {
  return (
    <Route render={({location}) => (
      <Switch location={location}>
        {/* <Route path="/admin/shops/:shop/app/dashboard" component={() => <AppWrapper component={Dashboard} />}/>
        <Route path="/admin/shops/:shop/app/settings" component={() => <AppWrapper component={Settings} />}/>
        <Redirect from="/admin/shops/:shop/app/*" to="dashboard"/> */}
        <Route path="/admin/shops/:shop" exact component={Shop}/>
        <Route path="/admin/shops" exact component={Shops}/>
        <Redirect from="/admin*" to={`/admin/shops${location.search}`}/>
      </Switch>
    )} />
  )
}

