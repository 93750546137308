import '../style.scss'

import { withFirebase } from '../../providers/firebase'

import React, { useEffect, useState, useCallback }from 'react';

import { useDispatch, useSelector } from 'react-redux'

import { useParams } from "react-router-dom";

import { withRouter } from 'react-router-dom'

import enTranslations from '@shopify/polaris/locales/en.json';


import {
  AppProvider,
  TopBar,
  Navigation,
  Frame,
} from '@shopify/polaris';

import {
  StoreMajor,
  LogOutMinor
} from '@shopify/polaris-icons';

import Loader from '../../components/Loader'

import { Routes } from './Routes'
import { AdapterLink } from '../Navigation'

import logo from '../../assets/app_icon_512.png';

import { adminSignInPath } from '../../utils/links'

const AdminLayout = (props) => { 
  const { auth, history, location } = props
  const redirect = location.pathname
  const historyReplace = history.replace
  const [authChecked, setAuthChecked] = useState(false)
  const [mobileNavActive, setMobileNavActive] = useState(false);
  const { shop } = useParams()
  const { isAgent, shop:storedShop } = useSelector(state => state.env)
  const dispatch = useDispatch()

  const togglemobileNavActive = useCallback(() => setMobileNavActive((x) => !x),[]);

  useEffect(() => {    
    if (shop && shop !== storedShop) dispatch({ type: 'SET_SHOP', payload: shop }) 
  },[shop, storedShop, dispatch])

  useEffect(() => {
    let authWatch = auth.onAuthStateChanged(user => {
      if (user) {
        auth.currentUser.getIdTokenResult()
          .then((token) => {            
            if (!token.claims.is_agent) {
              if (window.top === window.self) {
                auth.signOut()
                .then(() => {
                  historyReplace(`${adminSignInPath}?redirect=${redirect}`)
                })
              } else {
                historyReplace('/')
              }
            }
            authWatch()
            setAuthChecked(true)
          })
      } else {
        historyReplace(`${adminSignInPath}?redirect=${redirect}`)
      }
    })
  },[auth, isAgent, historyReplace, setAuthChecked, redirect])


  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      onNavigationToggle={togglemobileNavActive}
    />
  );

  const navigationMarkup = (
    <Navigation location="/admin">
      <Navigation.Section
        separator
        items={[
          // {
          //   label: 'Dashboard',
          //   url: '/admin/dashboard',
          //   icon: AnalyticsMajor,
          // },
          { label: 'Shops', url: '/admin/shops', icon: StoreMajor },
        ]}
      />
      <Navigation.Section
        separator
        items={[
          { label: 'Signout', url: `/signout?redirect=${adminSignInPath}`, icon: LogOutMinor },
        ]}
      />
    </Navigation>
  );

  const theme = {
    colors: { 
      topBar: {
        background: '#051e34',
      },
    },
    logo: {
      width: 200,
      topBarSource: logo,
    },
  };

  if (!authChecked) return null

  return (
    <AppProvider theme={theme} linkComponent={AdapterLink} i18n={enTranslations}>
      <Frame
          topBar={topBarMarkup}
          navigation={navigationMarkup}
          showMobileNavigation={mobileNavActive}
          onNavigationDismiss={togglemobileNavActive}
        >
          { authChecked 
            ? isAgent
              ? <Routes/>
              : <Loader/>
            : <Loader/>
          }
        </Frame>
    </AppProvider>
  )
}


export default withFirebase(withRouter(AdminLayout))