import React, {
  useState, useEffect, useCallback
} from "react"

import { 
  AppProvider,
  Banner,
  Button,
  Card,
  DisplayText,
  Form,
  FormLayout,
  Layout,
  Page,
  TextField 
} from "@shopify/polaris";

import {
  requestAuth
} from "../../utils/auth"

  
export default function Install (props) {
  const [shop, setShop] = useState('')
  const [missingShop, setMissingShop] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const queryParams = window.location.search
    const urlParams = new URLSearchParams(queryParams)
    const shop = urlParams.get('shop')
    
    if (shop) {
      const shopName = shop.replace('.myshopify.com', '');
      setShop(shopName)
    }
  },[])


  const handleChange = useCallback(
    (value) =>{
      setShop(value.replace('.myshopify.com', ''))
    },[setShop])

  const onSubmit = useCallback(
    () =>{
      setMissingShop(false)
      setError(false)
      setLoading(true)

      async function authenticateShop () {
        let auth = await requestAuth(`${shop}.myshopify.com`)

        if(!auth) {
          setError('There was a problem installing this app in your store. Please contact support')
          setLoading(false)
        }
      }
      
    if (shop.length > 0) {
        authenticateShop()
    } else {
      setMissingShop(true)
      setLoading(false)
    }
    },[shop, setMissingShop, setError, setLoading])
    
  
    const appName = process.env.REACT_APP_APP_NAME || 'App'

    return (
      <AppProvider
          shopOrigin={"myshopify.com"}
          apiKey={process.env.REACT_APP_SHOPIFY_API_KEY}
          forceRedirect={false}
      >
        <Page>
          <Layout>
            <Layout.Section>
            <div style={{maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto', padding: '0 2rem'}}>
                
                <Card sectioned>
                  <DisplayText size="large">{`Install ${appName}`}</DisplayText>
                  <br />
                  <Form method="POST" onSubmit={onSubmit}>
                    <FormLayout>
                      <TextField
                        id="shop"
                        name="shop"
                        value={shop}
                        onChange={handleChange}
                        label="Shop Domain"
                        type="text"
                        placeholder="example"
                        prefix="https://"
                        suffix=".myshopify.com"
                        error={missingShop && 'Shop domain is required'}
                        helpText={
                            <span>Enter your shop domain to log in or install this app.</span>
                        }
                        required=""
                      />
                      <Button primary submit loading={loading}>Install {appName}</Button>
                    </FormLayout>
                  </Form>
                </Card>
                {
                  error && (
                    <div style={{marginTop: '1.6rem'}}>
                      <Banner title="There was an error." status="critical">{error}</Banner>
                    </div>
                  )
                }
              </div>
            </Layout.Section>
          </Layout>
        </Page>
      </AppProvider>
    )
}
