import './style.scss'
import { withFirebase } from "../../../providers/firebase"

import React, { useState, } from "react"


import { AdapterLink } from '../../../layout/Navigation'

import { 
  AppProvider,
  Layout,
  Page,
  Card,
  Tabs,
} from "@shopify/polaris";

import { useCallback } from 'react'
import ShopsList from './ShopsList'

function Shops (props) {  
  const [tab, setTab] = useState(0)

  const tabs = [
    { id: 'active', content: 'Active' },
    { id: 'inactive', content: 'Inactive' },
  ]

  const handleTabChange = useCallback (setTab,[])

  
    return (
      <AppProvider linkComponent={AdapterLink}>
        <Page title="Shops">
          <Layout>
            <Layout.Section>
              <Card>
              <Tabs tabs={tabs} selected={tab} onSelect={handleTabChange}/>
              { tab === 0 && <ShopsList type="active"/>}
              { tab === 1 && <ShopsList type="inactive"/>}
              </Card>
            </Layout.Section>
          </Layout>
        </Page>
      </AppProvider>
    )
}


export default withFirebase(Shops)