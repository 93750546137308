import './style.scss'

import { withFirebase } from "../../../providers/firebase"

import React, {
  useState, 
  useEffect,
  useCallback,
} from "react"

import  { useParams } from "react-router-dom";

import { 
  Banner,
  Button,
  Layout,
  Page,
  AppProvider,
} from "@shopify/polaris";

import store from 'store-js'

import logo from '../../../assets/app_icon_512.png';
import Loader from '../../../components/Loader';
import { getQueryParam } from '../../../utils/helpers'

function SignIn (props) {
  const { firebase, auth, history } = props
  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [authChecked, setAuthChecked] = useState(false)
  const [signInCalled, setSignInCalled] = useState(false)
  const { shop } = useParams()  
  const redirect = getQueryParam('redirect') || `/admin`
  const registerAdmin = window.location.pathname === '/admin/signin/register'
  const historyReplace = history.replace

  const signIn = useCallback((result) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    setSignInCalled(true)
    store.set('shop', shop)

    auth.signInWithPopup(provider).then((result) => {
      setLoading(true)
      if (result.user) {        
        auth.currentUser.getIdTokenResult()
          .then((token) => {            
            if (token.claims && token.claims.is_agent) {
              window.location.replace(redirect) 
            } else {
              setLoading(false)
              auth.signOut()
              if (registerAdmin) {
                setMessage({title: 'Thank you', body: 'Your access reqeust has been submitted'})
              } else {
                setError('You are not an authorized user!')
              }
            }
          })
          .catch((error) => { 
            console.error(error)
            setLoading(false)
            setError(error.message)
          });
      }

    }).catch((error) => {
      setError(error.message)
    });
  },[firebase, auth, shop, setError, setMessage, setLoading, setSignInCalled, registerAdmin, redirect])


  useEffect(() => { 
    let authWatch = auth.onAuthStateChanged((user) => {
      setAuthChecked(true)
      if (signInCalled) return authWatch() 
      if (user) {        
        setLoading(true)
        auth.currentUser.getIdTokenResult()
          .then((token) => {            
            if (token.claims && token.claims.is_agent) {           
              historyReplace(redirect)
            } else {
              setLoading(false)
            }
          })
        authWatch() 
      }
    })
  },[auth, redirect, signInCalled, setLoading, setAuthChecked, historyReplace])

  if (!authChecked) return null

    return (
      <div className="ac-page-signin">
        <AppProvider>

          <Page>
            <Layout>
              <Layout.Section>
                { loading
                  ? <Loader major message="Signing in"/>
                  : ( 
                    <div className="ac-sign-in-wrap">
                      <img className="ac-sign-in-logo" src={logo} alt="logo"/>
                      <Button 
                      size="large"
                      onClick={signIn} 
                      icon={<img className="ac-signin-google-logo" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"></img>}
                      >
                        {registerAdmin ? 'Register with Google' : 'Signin with Google'}
                        </Button>
                    </div>
                  )
                  }
                <div style={{marginTop: '1.6rem'}}>
                  { error && <Banner title="Error" status="critical" onDismiss={() => setError(null)}>{error}</Banner> }
                  { message && <Banner title={message.title} status="info" onDismiss={() => setMessage(null)}>{message.body}</Banner> }
                </div>
              </Layout.Section>
            </Layout>
          </Page>
        </AppProvider>
      </div>
    )
}


export default withFirebase(SignIn)