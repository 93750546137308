import React, {useCallback, useState} from 'react';

import {
  ActionList, 
  Button, 
  Popover
} from '@shopify/polaris';

import {
  DeleteMajor, 
  CancelSmallMinor
} from '@shopify/polaris-icons';


export default function DeleteButton(props) {
  const { onDelete, children = "Delete", disabled } = props
  const [active, setActive] = useState(false);

  const toggleActive = useCallback((state) => {
    setActive(state)
  },[]);

  const handleDelete = useCallback((state) => {
    onDelete()
    toggleActive(false)
  },[onDelete, toggleActive]);

  const activator = typeof children === 'string' 
    ? <Button onClick={() => toggleActive(disabled && !active ? false : !active)} { ...props } disabled={disabled}>{children}</Button> 
    : <span  onClick={() => toggleActive(disabled && !active ? false : !active)} >{children}</span>

  const actions = [
    {content: 'Yes, Delete', destructive: true, icon: DeleteMajor, onAction: handleDelete}, 
    {content: 'Cancel', icon: CancelSmallMinor, onAction: () => toggleActive(false), active: false}
  ]

  return (
      <Popover
        active={active}
        activator={activator}
        onClose={() => toggleActive(false)}
      >
        <ActionList items={actions} />
      </Popover>
  );
}
