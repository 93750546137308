import './style.scss'
import { withFirebase } from "../../../providers/firebase"

import React, { useState, useEffect, } from "react"

import { useParams} from "react-router";

import { 
  Layout,
  Page,
  TextStyle,
  Card,
  Link,
} from "@shopify/polaris";

import {
  AppExtensionMinor
} from '@shopify/polaris-icons';


import Loader from '../../../components/Loader';

function Shop (props) {
  const { db } = props
  const [shopDetails, setShopDetails] = useState({})
  const [loading, setLoading] = useState(true)
  const { shop } = useParams()

  
  useEffect(() => {
    db.doc(`shops/${shop}/shop/shop`)
      .get()
      .then(s => {        
        setShopDetails(s.data())
        setLoading(false)
      })
  },[db, shop, setShopDetails])


    return (
      <Page 
      title={shopDetails.shop_name || shop} 
      breadcrumbs={[{content: 'Shops', url: '/admin/shops'}]} 
      primaryAction={{ content: 'Open App', url: `/admin/shops/${shop}/app/`, icon: AppExtensionMinor}}
      >
        { loading 
          ? <Loader/>
          : (
            <Layout>
              <Layout.Section>
                <Card title="Shop">
                  <Card.Section>
                  <p><TextStyle variation="Strong">{shopDetails.name}</TextStyle></p>
                  <Link href={`mailto:${shopDetails.email}`}>{shopDetails.email}</Link>
                  <p>{shopDetails.city}, {shopDetails.province}, {shopDetails.country_name}</p>
                  </Card.Section>
                </Card>
              </Layout.Section>
            </Layout>
            )
          }
        </Page>
    )
}


export default withFirebase(Shop)