import { withFirebase } from 'providers/firebase'

import React, { useRef, useEffect, useCallback, useState } from 'react';

import { useSelector } from 'react-redux'

import 'react-quill/dist/quill.snow.css'; 
import "quill-emoji/dist/quill-emoji.css";
import 'quill-mention/dist/quill.mention.min.css'


import uniqid from 'uniqid'


import ReactQuill from 'react-quill';
import Quill from 'quill';
import Delta from 'quill-delta';
import axios from 'axios'
import BlotFormatter from 'quill-blot-formatter';
import MagicUrl from 'quill-magic-url';
import quillEmoji from 'quill-emoji';
import { useLayoutEffect } from 'react';
import useWindowSize from 'hooks/useWindowSize';
import { useMemo } from 'react';
import QuillMention from 'quill-mention'
import { templateString } from 'utils/helpers';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'

Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register({
  'formats/emoji': EmojiBlot,
  'modules/emoji-shortname': ShortNameEmoji,
  'modules/emoji-toolbar': ToolbarEmoji,
  'modules/emoji-textarea': TextAreaEmoji
}, true);
Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register('modules/magicUrl', MagicUrl);
Quill.register('modules/mentions', QuillMention)


const FormatAttributesList = [
    'alt',
    'height',
    'width',
    'style'
];


var BaseImageFormat = Quill.import('formats/image');
class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return FormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (FormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
Quill.register(ImageFormat, true);


var BaseVideoFormat = Quill.import('formats/video');
class VideoFormat extends BaseVideoFormat {
  static formats(domNode) {
    return FormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (FormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
Quill.register(VideoFormat, true);

let BlockEmbed = Quill.import('blots/block/embed');
class DividerBlot extends BlockEmbed { }
DividerBlot.blotName = 'divider';
DividerBlot.tagName = 'hr';
Quill.register(DividerBlot);


var Icons = Quill.import('ui/icons');
Icons['divider'] = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  fill="#000000" version="1.1" x="0px" y="0px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve"><path d="M19,11H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h14c0.6,0,1-0.4,1-1S19.6,11,19,11z"/></svg>';


var Link = Quill.import('formats/link');
class MyLink extends Link {
  static create(value) {
    let node = super.create(value);
    value = this.sanitize(value);
    node.setAttribute('href', value);
    if((value.startsWith('/'))) {
      node.removeAttribute('target');
    }
    node.setAttribute('data-href', value);
    return node;
  }
}

Quill.register(MyLink);

var timer 
function ContentEditor (props) {
  const { 
    shop,
    firebase,
    storage,
    value,
    onChange,
    // onSave,
    editActive,
    imageRef,
    onSetLoading,
    onImageUploaded,
    keepToolbarInView,
  } = props

  const [ loadingImages, setLoadingImages ] = useState({})

  
  const editorRef = useRef(null)

  const { navigation: { articles_index = {} } } = useSelector(s => s)
  
  useEffect(() => { if (editActive && editorRef.current) editorRef.current.focus() }, [editActive, editorRef])
  
  useEffect(() => onSetLoading(Object.values(loadingImages).some(x => x)), [loadingImages, onSetLoading])

  const handleChange = useCallback((value, delta, source, editor) => onChange(value),[onChange])

  const { document: { width } } = useWindowSize()

  useLayoutEffect(() => {
      function updateToolbar () {

      if (timer) window.clearTimeout(timer)
      timer = setTimeout(() => {       
        if (keepToolbarInView !== undefined) {
          let el = document.getElementById('AC_Quill')
          let toolbarEl = document.getElementsByClassName(['ql-toolbar'])[0]
          let containerEl = document.getElementsByClassName(['ql-editor'])[0]
          let toolbarStyle

          if (toolbarEl) toolbarStyle = toolbarEl.getAttribute('style')

          if (editActive) {
            if (el) {
              let editorTopPos = el.getBoundingClientRect().y
              let paddingLeft = 0

              if (toolbarEl && containerEl && keepToolbarInView > editorTopPos) {
                containerEl.setAttribute( 'style', `padding-top: ${16 + toolbarEl.getBoundingClientRect().height}px;`)
                paddingLeft = document.documentElement.clientWidth - containerEl.getBoundingClientRect().right 
              } else if (containerEl) {
                containerEl.setAttribute('style', '')
              } 
              if (toolbarEl && keepToolbarInView > editorTopPos) {
                toolbarEl.setAttribute( 'style', `
                  position: fixed; 
                  top: ${keepToolbarInView}px;
                  z-index: 99999;
                  padding: 1rem ${paddingLeft}px;
                  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);;
                  `)
              } else if (toolbarEl) {
                toolbarEl.setAttribute('style', '')
              }        
            } 
          } else if (toolbarStyle) {
            if (containerEl) containerEl.setAttribute('style', '')
            if (toolbarEl) toolbarEl.setAttribute('style', '')
          }
        }
      }, 12)
    }

    window.addEventListener('scroll', updateToolbar);

    updateToolbar();

    return () => window.removeEventListener('scroll', updateToolbar);

  },[width, keepToolbarInView, editActive])


  const uploadFile = useCallback(async (file, id) => {
    return new Promise((resolve, reject) => {
      
      const path = `${imageRef}/${id}`
      
      var uploadTask = storage.ref(path).put(file, { id })
  
      uploadTask.on('state_changed',
        (snapshot) => console.log('Upload is ' + (snapshot.bytesTransferred / snapshot.totalBytes) * 100 + '% done'), 
        (error) => reject(error), 
        () => {
          uploadTask.snapshot.ref.getDownloadURL()
            .then(getDownloadURL => resolve(getDownloadURL))
        }
        )
    })
  },[storage, imageRef])

  const preLoadImage = useCallback(async (src) => {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = resolve
      img.onerror = reject
      img.src = src
    })
  },[])

  const deleteImage = useCallback((uri) => {
    try {
      let imagePath = decodeURIComponent(uri).split('appspot.com/o/')[1].split('?')[0]
      storage.ref(imagePath).delete()
    } catch (_error) {}
  },[storage])


  const hrHandler = useCallback(() => {
    const quill = editorRef.current.getEditor()
    let range = quill.getSelection(true);
    quill.insertText(range.index, '\n', Quill.sources.USER);
    quill.insertEmbed(range.index + 1, 'divider', true, Quill.sources.USER);
    quill.setSelection(range.index + 2, Quill.sources.SILENT);
  },[editorRef])


  const imageUploader = useCallback(async(file, placeholderSrc) => {
    const id = `image_${uniqid()}`
    const quill = editorRef.current.getEditor()

    function replaceImgSrc (oldSrc, newSrc) {
      let srcIsSet = false
      
      // get the editor content
      let ops = quill.getContents().ops
      
      // map the ops to find the image with the dataUrl just set. If it exists replace with the downloaded url
      ops = ops.map(op => {
        if (op.insert && op.insert.image) {
          if (op.insert.image === oldSrc) {
            op.insert.image = newSrc
            srcIsSet = true
          }
        }
        return op
      })
              
      // create a new delta based on modified content ops
      let delta = new Delta(ops)

      // get the cursor position so we reset and not interupat the user
      const { index: cursor } = quill.getSelection(true) || {}

      // set the content
      quill.setContents(delta)
            
      // Set the cursor so the user is not interupted
      if (cursor) quill.setSelection(cursor);
      return srcIsSet
    }

    try {
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml'];

      if (!validImageTypes.includes(file.type)) return 

      setLoadingImages(x => ({ ...x, [id]: true}))

      if (!imageRef) return

      const reader = new FileReader();
      
      reader.onload = async () => {
        const { index } = quill.getSelection(true);
        let dataUrl = reader.result

        if (!placeholderSrc) {
          placeholderSrc = dataUrl
          // insert the image as a placeholder with the dataUrl
          quill.insertEmbed(index, 'image', dataUrl); 
  
          // Move cursor to right side of image (easier to continue typing)
          quill.setSelection(index + 1);
        }

        // upload the file and get the downloadUrl
        const image = await uploadFile(file, id)

        onImageUploaded(image)
        
        // preload the image before swapping. This allows the browser to cache and user does not see load lag
        await preLoadImage(image)
        
        let srcSet = replaceImgSrc(placeholderSrc, image)

        if (!srcSet) deleteImage(image)

        setLoadingImages(x => ({ ...x, [id]: false}))
      }

      reader.readAsDataURL(file)
        
    } catch (error) {
      setLoadingImages(x => ({ ...x, [id]: false}))
    }
  },[uploadFile, imageRef, preLoadImage, deleteImage, onImageUploaded])

  const imageHandler = useCallback(async() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = () => imageUploader (input.files[0])
     
  },[imageUploader])

  const imagePasteHandler = useCallback(async(dataUrl, type, imageData) => {
    const quill = editorRef.current.getEditor()
    const { index } = quill.getSelection(true)

    const miniImageData = await imageData.minify({
      maxWidth: 1000,
      maxHeight: 1000,
      quality: 1
    })

    const miniImageDataUrl = miniImageData.dataUrl

    const blob = miniImageData.toBlob()

    quill.insertEmbed(index, 'image', miniImageDataUrl)

    imageUploader (blob, miniImageDataUrl)
     
  },[imageUploader, editorRef])
  

  const mentionSource = useCallback(async (searchTerm, renderList, mentionChar) => {
    let types = {
      // '@': {type: 'customer', minChar: 2, path: '/customers/', resources: 'customer.search', fields: 'id, first_name, last_name', value: '{{first_name}} {{last_name}}'},
      '@': {type: 'page', minChar: 0, path: '/pages/', resources: 'page.list', fields: 'id, title', value: '{{title}}'},
      '+': {type: 'collection', minChar: 0, path: '/collections/', resources: 'customCollection.list,smartCollection.list', fields: 'id, title', value: '{{title}}'},
    }
    let values
    console.log('in mentionsource');
  
    if (mentionChar === "#") {
      values = Object.keys(articles_index).map(id => ({id, value: `${articles_index[id].title}`, type: 'article', path: '/articles/', source: 'guide'}))

    } else if (types[mentionChar] && searchTerm.length >= types[mentionChar].minChar) {
      let token = await firebase.auth().currentUser.getIdToken()
      let { type, fields, path, value, resources } = types[mentionChar]

      let { data } = await axios({
        url: `${process.env.REACT_APP_BASE_SERVER_URL_STATIC}/api/v1/mentions/list?search=${searchTerm}&resources=${resources}&fields=${fields}`,
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'x-shop': shop
        }
      })

      values = data.map(v => ( {...v, value: templateString(value, v), type, path, source: 'admin'} ))
    } 

    values = (values || []).map(v => ({...v, mention: true}))

    if (searchTerm.length === 0) {
      renderList(values, searchTerm);
    } else {
      const matches = [];
      for (let i = 0; i < values.length; i++)
        if (
          ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
        )
          matches.push(values[i]);
      renderList(matches, searchTerm);
    }
  },[firebase, articles_index, shop])
  
  const modules = useMemo(() => {
    
    return {
      imageDropAndPaste: {
        handler: imagePasteHandler
      },
      clipboard: {
        matchVisual: false
      },
      toolbar: {
        handlers: {
          image: imageHandler,
          divider: hrHandler,
        },
        container: [
          [{ 'header': [1, 2, 3, 4, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote', 'code', 'code-block'],
          [{ 'color': ['#f44336','#E91E63','#9C27B0','#673AB7','#3F51B5','#2196F3','#03A9F4','#00BCD4','#009688','#4CAF50','#8BC34A','#CDDC39','#FFEB3B','#FFC107','#FF9800','#FF5722','#795548','#9E9E9E','#607D8B','#212b36']}],
          [{ 'align': [] }],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['emoji'],
          ['link', 'image', 'video'],
          ['divider'],
          ['clean']
        ]
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        isolateCharacter: true,
        dataAttributes: ['id', 'type', 'value', 'mention', 'path', 'source'],
        mentionDenotationChars: ["@", "#", '~', '+'],
        renderLoading: () => `<style>.loader {border: 3px solid transparent; border-top: 3px solid #449da7; border-right: 3px solid #449da7; border-bottom: 3px solid #449da7; border-radius: 50%;width: 24px;height: 24px;animation: spin .8s linear infinite;}@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}</style><div style="position:relative;height:44px"><div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)"><div class="loader"></div></div></div>`,
        source: mentionSource
      },
      magicUrl: true,
      ...(editActive ? {blotFormatter: {}} : {}),
      "emoji-toolbar": true,
      "emoji-shortname": true,
    }
  },[imageHandler, imagePasteHandler, editActive, hrHandler, mentionSource])
 

  const formats = [
    'header', 'color',
    'bold', 'italic', 'underline', 'strike', 'blockquote', 'code','code-block',
    'list', 'bullet', 'indent', 'align',
    'link', 'image', 'video',
    'height', 'width', 'style', //https://github.com/zenoamaro/react-quill/issues/330
    'emoji', 'divider', 'mention'
  ]
  
    return ( 
      <div id="AC_Quill" className={`AC-Article__Page-Content ${editActive ? ' Active' : ''}`}>
        <ReactQuill 
          value={value} 
          onChange={handleChange} 
          ref={editorRef} 
          readOnly={!editActive}
          formats={formats}
          modules={modules}
          />
      </div>   
    );
  }


  export default withFirebase(ContentEditor)