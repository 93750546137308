
import React from 'react'

import {
  DisplayText,
  Button,
  Stack,
} from "@shopify/polaris"

export default function NoArticles (props) {

  return (
    <div className="AC-Container Full-Height">
      <div className="AC-Row Align-Center Justify-Center Full-Height">
        <div className="AC-Col Flex Center-X">
          <Stack vertical alignment="center">
            <DisplayText size="extraLarge">No articles</DisplayText>
            <div>Create your first article to get started</div>
            <Button outline fullWidth size="large" url="/articles/new">New article</Button>
          </Stack>
        </div>

      </div>
    </div>
  )
}