import './style.scss'
import { withFirebase } from "../../../providers/firebase"

import React, {
  useState, 
  useEffect,
  } from "react"

import { useDispatch, useSelector } from 'react-redux'


import { 
  TextStyle,
  Icon,
  ResourceList,
  ResourceItem,
  Stack,
  Badge,
  Spinner,
} from "@shopify/polaris";

import {
  StoreMajor
} from '@shopify/polaris-icons';

import { sortArray } from '../../../utils/helpers'

function Shops (props) {  
  const { db, type } = props
  const { admin } = useSelector(s => s)
  const shops = admin[`${type}_shops`] || []
  
  const [loading, setLoading] = useState(shops.length === 0)
  const dispatch = useDispatch()

  console.log(Boolean(type === 'active'));
  

  useEffect(() => {
    const listener = db.collection('shops')
      .where('app_installed', '==', Boolean(type === 'active'))
      .onSnapshot(s => {
        setLoading(false)
        dispatch({
          type: type === 'active' ? 'SET_ACTIVE_SHOPS': 'SET_INACTIVE_SHOPS',
          payload: sortArray(s.docs.map(d => ({ ...d.data(), shop: d.id })), 'requests_open')
        })
      })
      return listener
  },[db, type, dispatch])

  console.log(shops);
  
  
  return (
    <>
      { loading 
        ? <div style={{height: '250px', display: 'flex', placeItems: 'center', justifyContent: 'center'}}><Spinner/></div>
        : <>
            { shops.length > 0
              ? <ResourceList
                resourceName={{singular: 'shop', plural: 'shops'}}
                items={shops}
                renderItem={RenderItem}
              />
              : <ResourceItem>No shops</ResourceItem>
            }
          </>
      }
    </>
  )
}

function RenderItem (item) {
  const {shop, shop_name, requests_open } = item;
  const url = `/admin/shops/${shop}`

  return (
    <ResourceItem
      id={shop}
      url={url}
      media={<Icon source={StoreMajor} color="inkLight" />}
    >
      <Stack alignment="center">
        <Stack.Item fill>
          <TextStyle variation="strong">{shop_name}</TextStyle>
          <div>{shop}</div>
        </Stack.Item>
        { requests_open > 0 && <Badge status="attention">Requests ({requests_open})</Badge> }
      </Stack>
    </ResourceItem>
  );
}

export default withFirebase(Shops)